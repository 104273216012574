@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --text: #1a1a1a;
  --cardBackground: #ffffff;
  --subtleText: #6b7280;
  --border: #E8E8E8;
  --coloredButton: #5260d1;
  --newsCardBackground: #f3f3f3;
  --selectedOptionBackground: #EBF3FF;
  --correctOptionBackground:#E4FFEF;
  --wrongOptionBackground:#FFB6B6;
  --button-border: #000;
  --disable-text-color:#747474;
  --deleteButton-background:#070519;
  --disableButton-background:#D1D1D1;
  --enableButton-background:#070519;
  --tab-button-background:#FFFFFF;
  --continar-tab-background:#F3F3F3;
  --card-defeat-background-color:#FFEDED;
  --card-victory-background-color:#61BD85;
  --card-draw-background-color:#d5ddf9;
  --highlight-text:#070519;
  --highlight-text-background: #E1EBFF;
  --text-color:#B9B9B9;
  --correct-spark-question:#CBF7DC;
  --correct-spark-border:#61BD85;
  --wrong-spark-question:#FFEDED;
  --wrong-spark-border:#FFB6B6;

}

[data-theme='dark'] {
  --background: #18181A;
  --text: #f9fafb;
  --cardBackground: #303034;
  --subtleText: #9ca3af;
  --border: #464646;
  --coloredButton: #5260d1;
  --newsCardBackground: #18181a;
  --selectedOptionBackground: #1B3260;
  --correctOptionBackground:#2F6545;
  --wrongOptionBackground:#9F4749;
  --button-border:#464646;
  --disable-text-color:#a2a2a2;
  --deleteButton-background:#D95353;
  --disableButton-background:#2E2E2E;
  --enableButton-background:#FFFFFF;
  --tab-button-background:#57575B;
  --continar-tab-background:#303034;
  --card-defeat-background-color:#D953533D;
  --card-victory-background-color:#2F6545;
  --card-draw-background-color:#2E2E2E;
  --highlight-text:#F4FAFF;
  --highlight-text-background: #3C517A;
  --text-color:#464646;
  --correct-spark-question:#61BD853D;
  --correct-spark-border:#61BD853D;
  --wrong-spark-question:#D953533D;
  --wrong-spark-border:#D953533D;

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

[data-mantine-color-scheme='light'] .m_89d60db1 {
  --tab-border-color: red;
}

.m_576c9d4::before {
  border-style: hidden !important;
}

.m_539e827b:where([data-active]) {
  border-color: black !important;
}

::-webkit-scrollbar {
  width: 1em;
}

.carousel .control-dots {
  top: 0;
}

.custom-scrollbar {
  @apply overflow-y-scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* height: 100%; */
}

.custom-scrollbar::-webkit-scrollbar {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

/* mantine placeholder text override  */
.mantine-placeholder-color input::placeholder {
  color: #747474; 
}

