/* Default styles for active state */
.active .icon {
  color: #585aed; /* Icon turns blue by default */
}

.active .label {
  color: black; /* Text turns black by default */
  position: relative;
}

.active .label::after {
  content: '';
  position: absolute;
  bottom: -2px; /* Adjust this value if needed */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background-color: #585aed; /* Blue underline by default */
}

/* Styles for dark theme */
.dark-theme-active .icon {
  color: #585aed; /* Icon turns white in dark theme */
}

.dark-theme-active .label {
  color: white; /* Text turns white in dark theme */
}

.dark-theme-active .label::after {
  background-color: #585aed /* White underline in dark theme */
}
